import React, { Component } from 'react'
import { withRouter } from "react-router-dom";

class ScrollToTop extends Component {
    componentDidUpdate(prevProps) {
        // Valida que el pathname sea diferente al actual para setear el scroll
        if ( this.props.location.pathname !== prevProps.location.pathname) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }

    render() {
        return null;
    }
}
export default withRouter(ScrollToTop);