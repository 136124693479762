import { api } from 'api';
import Swal from 'sweetalert2';
import { handleActions } from "redux-actions";

// ------------------------------------
// Constants
// ------------------------------------
const PAGE = 'PHOME_TIENDA_AGE';
export const DATA = "HOME_TIENDA_ONLINE_DATA";
const CATEGORIAS = "CATEGORIASHOME_TIENDA_ONLINE";
export const LOADING = "HOME_TIENDA_ONLINE_LOADING";
const CATEGORIAS_ACTIVAS = "CATEGORIAS_ACTIVAS_TIENDA_ONLINE";
const CATEGORIA_SELECCIONADA = 'CATEGORIA_SELECCIONADAHOME_TIENDA_ONLINE';

const endpoint = "tienda_linea";
const endpointCategorias = "categoria_empresa";

// ------------------------------------
// Actions
// ------------------------------------

export const list = () => (dispatch, getStore) => {
    const store = getStore();
    const { page, categoria_seleccionada } = store.home;
    dispatch(setLoading(true))

    let params = {
        page
    }

    //  Se aplica la categoría seleccionada para filtrar los productos
    if (categoria_seleccionada) {
        params.categoria = categoria_seleccionada
    }

    api.get(`${endpoint}/empresas`, params).catch(err => {
        Swal.fire("ERROR", "No se ha podido obtener los datos, intenta más tarde", "error")
    }).then(resp => {
        if (resp) {
            dispatch(setData(resp));
        }
    }).finally(() => {
        dispatch(setLoading(false));
    })
}

const pageChange = page => (dispatch) => {
    dispatch(setPage(page));
    dispatch(list());
};

export const getCategorias = () => (dispatch, getStore) => {
    // dispatch(setLoading(true))
    api.get(`${endpointCategorias}/tree`).catch(err => {
        console.log("ERROR: ", err);
        ToastStore.error("No fue posible obtener el listado de categorías")
    }).then(resp => {
        console.log(resp);
        if(resp) {
            // const tree = dispatch(formatCategorias(resp.data));
            dispatch(setCategorias(resp.data));
        }
    }).finally(() => {
        // dispatch(setLoading(false))
    })
}

const encontrarCategoria = (data, id) => {
    let encontrado = null
    data.forEach(categoria => {
        if (categoria.id == id) {
            encontrado = categoria;
        } else if (categoria.childs.length) {
            if (!encontrado) {
                const result = encontrarCategoria(categoria.childs, id)
                encontrado = result ? result : null
            }
        } else {
            if (!encontrado) {
                encontrado = false
            }
        }
    })
    return encontrado
}

const findCategoria = (data, id) => {
    let encontrado = null
    data.forEach(categoria => {
        if (categoria.id == id) {
            encontrado = categoria;
        } else if (categoria.childs.length) {
            if (!encontrado) {
                const result = encontrarCategoria(categoria.childs, id)
                encontrado = result ? result : null
            }
        }else {
            if (!encontrado) {
                encontrado = false
            }
        }
    })
    return encontrado;
}

export const changeCategoria = (id) => (dispatch, getStore) => {
    const store = getStore();
    const { categorias } = store.home;
    dispatch(setCategoriaSeleccionada(id));
    
    //  Se busca la categoría
    if (id) {
        const result = findCategoria(categorias, id);
        if (result) {
            dispatch(setCategoriasActivas([result]))
        }else {
            ToastStore.error("No se encontro la categoría")
        }
    }else {
        dispatch(setCategoriasActivas([]))
    }

    dispatch(setPage(1))
    dispatch(list())
}

// export const changeCategoria = (id) => (dispatch) => {
//     dispatch(setCategoriaSeleccionada(id));
//     dispatch(list());
// }

// ------------------------------------
// PureActions
// ------------------------------------
export const setData = data => ({
    type: DATA,
    data
})

export const setLoading = loader => ({
    type: LOADING,
    loader
})

const setPage = page => ({
    type: PAGE,
    page,
});
const setCategorias = categorias =>  ({
    type: CATEGORIAS,
    categorias
});
const setCategoriaSeleccionada = categoria_seleccionada =>  ({
    type: CATEGORIA_SELECCIONADA,
    categoria_seleccionada
});
const setCategoriasActivas = categorias_activas =>  ({
    type: CATEGORIAS_ACTIVAS,
    categorias_activas
});

export const actions = {
    list,
    pageChange,
    getCategorias,
    changeCategoria,
}
// ------------------------------------
// Reducers
// ------------------------------------
export const reducers = {
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [LOADING]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [CATEGORIAS]: (state, {categorias}) => {
        return {
            ...state,
            categorias,
        };
    },
    [CATEGORIA_SELECCIONADA]: (state, {categoria_seleccionada}) => {
        return {
            ...state,
            categoria_seleccionada,
        };
    },
    [CATEGORIAS_ACTIVAS]: (state, {categorias_activas}) => {
        return {
            ...state,
            categorias_activas,
        };
    },
}

export const initialState = {
    data: [],
    loader: false,
    page: 1,
    categorias: [],
    categoria_seleccionada: null,
    categorias_activas: [],
}

export default handleActions(reducers, initialState);
