import React, {Component} from 'react'
import { Link } from 'react-router-dom';
import LoadMask from "Utils/LoadMaskTO";
import Listado from './Listado';
import './listado.css'
import Footer from '../Footer';

export default class ListadoProductos extends Component{
    constructor(props) {
        super(props)
    
        this.state = {
             url: "",
             width: window.innerWidth
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.reportWindowSize);
    }
    
    componentWillMount() {
        const { match, location } = this.props;
        this.setState({url: match.url})
        this.props.setUrlProductos(match.url)
        let slug_categoria = null;
        
        this.props.setData({
            count: 0,
            empresa: [],
            results: [],
        });
        if (match.url === location.pathname) {
            this.props.asignarEmpresa(match.params.slug_empresa);
        }else {
            const partes = location.pathname.split(match.url)
            const slugs = partes[1].split("/")
            if (slugs[slugs.length - 1] == "") {
                slug_categoria =  slugs.length > 1  ? slugs[slugs.length - 2] : null
            }else {
                slug_categoria = slugs[slugs.length - 1]
            }
            this.props.asignarEmpresa(match.params.slug_empresa, slug_categoria)
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.reportWindowSize);
    }

    reportWindowSize = (event) => {
        this.setState({width: window.innerWidth})
    }

    render() {
        const { data, loader } = this.props;
        const isTabletDesktop = this.state.width > 767;
        return(
            <div style={{backgroundColor: "#F5F5F5"}} id="LISTADO-PRODUCTOS">
                <LoadMask loading={loader} dark blur>
                    <Listado {...this.props} url={this.props.url_productos} isTabletDesktop={isTabletDesktop}/>
                </LoadMask>
            </div>
        )
    }
}

