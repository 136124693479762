import React from 'react';
import NumberFormat from 'react-number-format';

export const RenderMoneda = ({monto, pais, className}) => {
    if (!!monto) {
        monto = parseFloat(monto).toFixed(2);
    }
    if (monto || monto === 0) {
        return (
            <NumberFormat className={className}
                decimalScale={2} fixedDecimalScale={true}
                value={monto} thousandSeparator={true} prefix={ pais == "GT" ? "Q ":"$ "} displayType={"text"}
            />
        );
    }
    return <span className={className}>Sin registro</span>;
};

export const RenderNumero = ({value, decimalScale, className}) => {
    if (!!value) {
        value = parseFloat(value).toFixed(0);
    }
    if (value || value === 0) {
        return (
            <NumberFormat className={className}
                decimalScale={decimalScale ? decimalScale : 0} fixedDecimalScale={true}
                value={value} thousandSeparator={true} prefix={''} displayType={"text"}
            />
        )
    }
    return <span className={className}>Sin registro</span>;
};
