
export const registrarEmpresa = (datos) => {
    let carrito = JSON.parse(localStorage.getItem(`cart-${datos.slug}`));
    if (carrito == null) {
        localStorage.setItem(`cart-${datos.slug}`, JSON.stringify({
            empresa: datos,
            productos: []
        }))
    }else {
        carrito.empresa = datos;
        localStorage.setItem(`cart-${datos.slug}`, JSON.stringify(carrito));
    }
}

export const obtenerEmpresa = (slug_empresa) => {
    let carrito = JSON.parse(localStorage.getItem(`cart-${slug_empresa}`));
    if (carrito == null) {
        return null
    }
    return carrito.empresa;
}

export const registrarProductos = (productos, slug_empresa) => {
    let carrito = JSON.parse(localStorage.getItem(`cart-${slug_empresa}`));
    if (carrito == null) {
        localStorage.setItem(`cart-${slug_empresa}`, JSON.stringify({
            empresa: null,
            productos: productos
        }))
    }else {
        carrito.productos = productos;
        localStorage.setItem(`cart-${slug_empresa}`,JSON.stringify(carrito));
    }
}

export const obtenerProductos = (slug_empresa) => {
    let carrito = JSON.parse(localStorage.getItem(`cart-${slug_empresa}`));
    if (carrito == null) {
        return []
    }
    return carrito.productos;
}

export const limpiarProductos = (slug_empresa) => {
    let carrito = JSON.parse(localStorage.getItem(`cart-${slug_empresa}`));
    if (carrito == null) {
        localStorage.setItem(`cart-${slug_empresa}`, JSON.stringify({
            empresa: null,
            productos: []
        }))
    } else {
        carrito.productos = [];
        localStorage.setItem(`cart-${slug_empresa}`, JSON.stringify(carrito));
    }
}
