import { api } from "api";
import { push } from "react-router-redux";
import { setMe } from "../usuario/usuario";
import { handleActions } from "redux-actions";
import { NotificationManager } from "react-notifications";
import { initialize as initializeForm } from "redux-form";

const LOADER = "PROFILE_LOADER";
const DATOS_PERFIL = "PROFILE_DATOS_PERFIL";
const MODAL_PERFIL = "PROFILE_MODAL_PERFIL";
const MODAL_CHANGE_PASSWORD = "PROFILE_MODAL_CHANGE_PASSWORD";

export const constants = {};
const endpoint = "usuarios_online";

// ------------------------------------
// Pure Actions
// ------------------------------------
export const setLoader = (loader) => ({
    type: LOADER,
    loader,
});
export const setDatosPerfil = (datos_perfil) => ({
    type: DATOS_PERFIL,
    datos_perfil,
});
export const setModalPerfil = (modal_perfil) => ({
    type: MODAL_PERFIL,
    modal_perfil,
});
export const setModalChangePassword = (modal_password) => ({
    type: MODAL_CHANGE_PASSWORD,
    modal_password,
});

// ------------------------------------
// Actions
// ------------------------------------

export const update = (data = {}) => (dispatch, getStore) => {
    const store = getStore();
    const { datos_perfil } = store.profile;
    dispatch(setLoader(true));
    api.put(`${endpoint}/${datos_perfil.id}/actualizar_perfil`, data)
        .then((response) => {
            dispatch(setMe(response));
            dispatch(setDatosPerfil(response));
            dispatch(setModalPerfil(false));
            NotificationManager.success(
                "Datos actualizados exitosamente",
                "ÉXITO",
                1000
            );
        })
        .catch((err) => {
            if (err) {
                NotificationManager.error(
                    err.detail ||
                        "No fue posible actualizar los datos, vuelva a intentar más tarde",
                    "ERROR",
                    0
                );
            } else {
                NotificationManager.error(
                    "No fue posible actualizar los datos, vuelva a intentar más tarde",
                    "ERROR",
                    0
                );
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const getInfoUser = () => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${endpoint}/me`, {})
        .catch(() => {
            localStorage.removeItem("token");
            NotificationManager.error(
                "No fue posible obtener los datos, intenta más tarde",
                "ERROR",
                0
            );
        })
        .then((me) => {
            if (me) {
                dispatch(setDatosPerfil(me));
                dispatch(
                    initializeForm("editarPerfilForm", {
                        first_name: me.first_name,
                        last_name: me.last_name,
                        telefono: me.telefono,
                        email: me.email,
                    })
                );
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const changePassword = (data = {}) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const store = getStore();
    const { datos_perfil } = store.profile;
    api.put(`${endpoint}/${datos_perfil.id}/change_password`, data)
        .then((response) => {
            dispatch(setModalChangePassword(false));
            NotificationManager.success(
                "Contraseña actualizados exitosamente",
                "ÉXITO",
                1000
            );
        })
        .catch((err) => {
            if (err) {
                NotificationManager.error(
                    err.detail ||
                        "No fue posible actualizar la contraseña, vuelva a intentar más tarde",
                    "ERROR",
                    0
                );
            } else {
                NotificationManager.error(
                    "No fue posible actualizar la contraseña, vuelva a intentar más tarde",
                    "ERROR",
                    0
                );
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const actions = {
    update,
    getInfoUser,
    changePassword,
    setModalPerfil,
    setModalChangePassword,
};

// ------------------------------------
// Reducers
// ------------------------------------
export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATOS_PERFIL]: (state, { datos_perfil }) => {
        return {
            ...state,
            datos_perfil,
        };
    },
    [MODAL_PERFIL]: (state, { modal_perfil }) => {
        return {
            ...state,
            modal_perfil,
        };
    },
    [MODAL_CHANGE_PASSWORD]: (state, { modal_password }) => {
        return {
            ...state,
            modal_password,
        };
    },
};

// ------------------------------------
// InitialState
// ------------------------------------
export const initialState = {
    loader: false,
    datos_perfil: null,
    modal_perfil: false,
    modal_password: false,
};

export default handleActions(reducers, initialState);
