import './footer.css';
import React from 'react';

const Footer = ({
    props,
}) => (
    <div className="footer">
        <div className="container-footer py-3">
            <div className="row">
                <div className="col-12 col-md-6 col-lg-4 d-flex flex-column justify-content-center align-items-center">
                    <img src={require(`assets/img/compralo_blanco.png`)} height="50" className="d-inline-block align-top" alt="" />
                    <div className="derechos text-center mt-1">
                        Todos los Derechos Reservados 2020
                        </div>
                </div>
                <div className="col-12 col-md-6 col-lg-8 d-flex flex-column justify-content-center align-items-md-start align-items-lg-start align-items-center mt-4 mt-lg-0">
                    <div className="d-flex justify-content-center txt-contactenos" style={{fontSize: '20px'}}>
                        <label className="mb-4">¿Deseas tener tu propia tienda? contáctanos</label>
                    </div>
                    <div className="row w-100">
                        <div className="col-12 col-lg-4">
                            <a href="https://api.whatsapp.com/send?phone=+5023554-4602" target="_blank" className="a-remove-fromat my-1 mt-md-0 mt-lg-0">
                                <span className="d-flex d-flex flex-column flex-md-row flex-lg-row align-items-center" style={{fontSize: "14px"}}>
                                    <i className="fa fa-whatsapp fa-2x mr-0 mr-md-3 mr-lg-3" aria-hidden="true" />
                                    3554-4602
                                </span>
                            </a>
                        </div>
                        <div className="col-12 col-lg-4">
                            <a href="https://www.facebook.com/gtvendemas/" target="_blank" rel="Facebook Vendemas" className="d-flex flex-column flex-md-row flex-lg-row align-items-center a-remove-fromat my-1">
                                <img src={require(`assets/img/icons/facebook.png`)} width="23" height="23" className="d-inline-block align-top mr-0 mr-md-3 mr-lg-3" alt="" />
                                <span style={{fontSize: "14px"}}>Facebook</span>
                            </a>
                        </div>
                        <div className="col-12 col-lg-4">
                            <span className="d-flex flex-column flex-md-row flex-lg-row align-items-center my-1" style={{fontSize: "14px"}}>
                                <i className="fa fa-envelope-o fa-2x mr-0 mr-md-3 mr-lg-3" aria-hidden="true" style={{fontSize: "24px"}} /> ventas@ciancoders.com
                            </span>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
);

export default Footer;
