import { api } from "api";
import Swal from "sweetalert2";
import { ToastStore } from 'react-toasts';
import { handleActions } from "redux-actions";

const endpoint = "tienda_linea";

// ------------------------------------
// Constants
// ------------------------------------
export const LOADER = "OR_ONLINE_LOADER";
export const DATA = "OR_ONLINE_DATA";
export const DETALLE = "OR_ONLINE_DETALLE";
export const SORT = "OR_ONLINE_SORT";
export const PAGE = "OR_ONLINE_PAGE";
export const MODAL_PAGO = "OR_ONLINE_MODAL_PAGO";

// ------------------------------------
// Actions
// ------------------------------------

export const listar = (page = 1) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const store = getStore();
    const { ordering, page } = store.ordenes_online;
    const params = { ordering, page, ordenes: true };
    api.get(`${endpoint}`, params ).catch((err) => { }).then((data) => {
        if (data) {
            console.log(data)
            dispatch(setData(data));
        }
        dispatch(setPage(page));
        dispatch(setLoader(false));
    });
};


export const cargarOrden = (codigo) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    api.get(`${endpoint}/orden_online`, { codigo_venta: codigo }).catch((err) => {
        dispatch(setLoader(false));
    }).then((resp) => {
        if (resp) {
            console.log(resp)
            dispatch(setDetalle(resp));
        }
        dispatch(setLoader(false));
    });
};

export const corfirmarPago = (orden) => (dispatch, getStore) => {
    const store = getStore();
    const { detalle } = store.orden_online;
    let data = null;
    if (detalle.forma_pago != "EFE" && detalle.forma_pago != "PCE" && detalle.forma_pago != "PT") {
        data = store.form.BoletaForm.values;
    }
    dispatch(setLoader(true));
    api.put(`${endpoint}/${orden}/confirmar_pago_orden`, data).catch((err) => {
        dispatch(setLoader(false));
        Swal(
            'ERROR',
            err.detail || 'Ha ocurrido un error, intenta más tarde.',
            'error'
        );
    }).then((resp) => {
        if (resp) {
            ToastStore.success("Se ha confirmado el pago ");
            dispatch(setDetalle(resp));
            dispatch(setModalPago(false))
        }
        dispatch(setLoader(false));
    });
};

export const sortChange = (sortName, sortOrder) => (dispatch, getStore) => {
    if (sortOrder === 'asc') {
        dispatch(setSort(sortName));
    } else {
        dispatch(setSort(`-${sortName}`));
    }
    const store = getStore();
    const page = store.sucursal.page;
    dispatch(listar(page));
};

// ------------------------------------
// PureActions
// ------------------------------------

export const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const setData = data => ({
    type: DATA,
    data,
});

export const setDetalle = detalle => ({
    type: DETALLE,
    detalle,
});

export const setPage = sort => ({
    type: SORT,
    sort,
});

export const setSort = ordering => ({
    type: SORT,
    ordering,
});
export const setModalPago = modal_pago => ({
    type: MODAL_PAGO,
    modal_pago,
});

export const actions = {
    listar,
    sortChange,
    cargarOrden,
    setModalPago,
    corfirmarPago,
};


// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [DETALLE]: (state, { detalle }) => {
        return {
            ...state,
            detalle,
        };
    },
    [SORT]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [MODAL_PAGO]: (state, { modal_pago }) => {
        return {
            ...state,
            modal_pago,
        };
    },
};

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    data: {},
    loader: false,
    page: 1,
    ordering: "-creado",
    detalle: {},
    modal_pago: false,
};

export default handleActions(reducers, initialState);
