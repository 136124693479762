import { connect } from 'react-redux';
import { actions } from '../../../redux/modules/productos';
import { agregarProducto } from '../../../redux/modules/carrito_compras/carrito_compras';
import ListadoProductos from './ListadoProductos';

const ms2p = (state) => {
    return {
        ...state.productos,
    };
};

const md2p = {
    ...actions,
    agregarProducto: (producto) => (dispatch) => dispatch(agregarProducto(producto))
};

export default connect(ms2p, md2p)(ListadoProductos);
