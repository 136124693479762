import React from 'react'
import { renderField } from "Utils/renderField/renderField"
import { Field, reduxForm } from 'redux-form'
import LoadMask from "Utils/LoadMask/LoadMask";
import { icons } from "icons";

const validate = values => {
    const errors = {};

    if (!values.email)
        errors.email = 'Campo requerido';
    if (values.email) {
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            errors.email = 'Dirección de correo invalida'
        }
    }
    return errors;
};

const Form = (props) => {
    // state
    // bind
    const { handleSubmit, loader } = props;

    return (
        <form name="loginForm" onSubmit={handleSubmit} className="form-validate mb-lg">
            <p className="mb-2 d-none d-sm-inline-block text-left">Escribe el correo electrónico enlazado a tu cuenta de <b>Remocar</b>, se te enviara un correo con los siguientes pasos</p>
            <small className="mb-2 d-inline-block d-sm-none text-left">Escribe el correo electrónico enlazado a tu cuenta de <b>Remocar</b>, te enviaremos un correo con los pasos a seguir</small>
            <div className="form-group has-feedback">
                <span className="fa fa-envelope form-control-feedback text-muted text-muted-login" />
                <Field name="email" component={renderField} type="text" className="form-control form-control-login" label="Correo electrónico"/>
            </div>
            <LoadMask loading={loader} light blur>
                <div className="row">
                    <div className="col-12 p-0">
                        <div className="d-flex justify-content-center flex-row">
                            <button type="submit" className="w-100 btn btn-primary m-1 align-self-center">
                                Recuperar Contraseña
                            </button>
                        </div>
                    </div>
                </div>
            </LoadMask>
        </form>

    );
};

let RecuperarForm = reduxForm({
    form: 'RecuperarForm',
    validate
})(Form);

export default RecuperarForm;
