import './estado_compra.css'
import { connect } from 'react-redux';
import DetalleEstadoCompra  from './DetalleEstadoCompra';
import { actions } from '../../../redux/modules/orden_online/orden_online';

const ms2p = (state) => {
    return {
        ...state.orden_online,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(DetalleEstadoCompra);

