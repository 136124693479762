import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { renderField} from "Utils/renderField/renderField";

// VALIDACIONES CUSTOMS
const validate = (values, props) => {
    const errors = {}
    if (props.forma_pago == "DEP")
        if (!values.banco)
            errors.banco = "Campo requerido"
    if (!values.num_documento)
        errors.num_documento = "Campo requerido"
    return errors;
}

const Form = (props) => {
    const { handleSubmit, close, loader, forma_pago } = props;

    return (
        <form onSubmit={handleSubmit} className="col-sm-12 pt-2">
            <div className="form-group">
                {
                    forma_pago == "DEP" && (
                        <div className="row">
                            <div className="col-12 form-group">
                                <label htmlFor="banco">Banco</label>
                                <Field name="banco" label="Ingrese el nombre del banco" component={renderField} type="text" className="form-control" autoFocus={true}
                                />
                            </div>
                        </div>
                    )
                }
                {
                    forma_pago != "DEP" ? (
                        <div className="row">
                            <div className="col-12 form-group">
                                <label htmlFor="num_documento">No. Transacción</label>
                                <Field name="num_documento" label="Ingrese el numero de coumento" component={renderField} type="text" className="form-control" autoFocus={true}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className="row">
                            <div className="col-12 form-group">
                                <label htmlFor="num_documento">No. Transacción</label>
                                <Field name="num_documento" label="Ingrese el numero de coumento" component={renderField} type="text" className="form-control"
                                />
                            </div>
                        </div >
                    )
                }

                <div className="d-flex justify-content-center flex-row">
                    <button type="button" className="btn btn-secondary m-1 align-self-center" onClick={(e) => {
                        e.stopPropagation();
                        close({});
                    }}>Cancelar</button>
                    <button type="submit" className="btn btn-primary m-1 align-self-center" disabled={loader}>Confirmar</button>
                </div>
            </div>
        </form>
    )
}

const BoletaForm = reduxForm({
    // a unique name for the form
    form: 'BoletaForm',
    validate
})(Form)

export default BoletaForm;
