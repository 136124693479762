import { handleActions } from "redux-actions";
import { api } from "api";
import { NotificationManager } from "react-notifications";
import history from "../../../utility/history";
import { initialize as initializeForm } from 'redux-form';

// ------------------------------------
// Constants
// ------------------------------------
const ME = "ME_USUARIO";
const LOADER = "LOADER_USUARIO";
const REGISTRO_COMPLETO = "REGISTRO_COMPLETO_USUARIO";

// ------------------------------------
// Actions
// ------------------------------------
export const getMe = () => (dispatch) => {
    const is_authenticated = localStorage.getItem("token");

    if ( is_authenticated) {
        api.get("usuarios_online/me", {})
            .catch(() => {
                localStorage.removeItem("token");
            })
            .then(me => {
                if (me) {
                    dispatch(setMe(me));
                }
            });
    }
}

export const inicializarFormCompletarDatos = () => (dispatch) => {
    dispatch(setLoader(true));
    dispatch(setRegistroCompleto(false))
    api.get("usuarios_online/me", {})
        .catch(() => {
            localStorage.removeItem("token");
            NotificationManager.error('No fue posible obtener los datos, intenta más tarde', 'ERROR', 0);
        })
        .then(me => {
            if (me) {
                dispatch(setMe(me));
                dispatch(initializeForm("datosPerfilForm", {
                    first_name: me.first_name,
                    last_name: me.last_name,
                    telefono: me.telefono
                }))
            }
        }).finally(() => {
            dispatch(setLoader(false));
        });
}
export const completarDatosRegistro = (datos) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const store = getStore();
    const { me } = store.usuario;
    api.put(`usuarios_online/${me.id}`, datos).catch(err => {
        NotificationManager.error('No fue posible registrar los datos, intenta más tarde', 'ERROR', 0);
    }).then(resp => {
        if (resp) {
            dispatch(setRegistroCompleto(true))
            dispatch(getMe())
            NotificationManager.success('Datos actualizados exitosamente', 'Éxito', 3000);
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const registroCompleto = (location) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const store = getStore();
    const { me } = store.usuario;
    api.put(`usuarios_online/${me.id}/registro_completo`, {}).catch(err => {
        // NotificationManager.error('No fue posible registrar los datos, intenta más tarde', 'ERROR', 0);
    }).then(resp => {
        if (resp) {
            dispatch(setRegistroCompleto(true))
        }
    }).finally(() => {
        dispatch(setLoader(false));
        if (location.state) {
            if (location.state.from) {
                history.push(location.state.from.pathname)
            }else {
                history.push("/")
            }
        }else {
            history.push("/")
        }
    });
}

// ------------------------------------
// PureActions
// ------------------------------------
export const setMe = me => ({
    type: ME,
    me
});
export const setLoader = loader => ({
    type: LOADER,
    loader
});
export const setRegistroCompleto = registro_completo => ({
    type: REGISTRO_COMPLETO,
    registro_completo
});

export const actions = {
    getMe,
    completarDatosRegistro,
    registroCompleto,
    inicializarFormCompletarDatos,
}

// ------------------------------------
// Reducers
// ------------------------------------
export const reducers = {
    [ME]: (state, { me }) => {
        return {
            ...state,
            me
        };
    },
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader
        };
    },
    [REGISTRO_COMPLETO]: (state, { registro_completo }) => {
        return {
            ...state,
            registro_completo
        };
    }
}

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    me: null,
    loader: false,
    registro_completo: false
}

export default handleActions(reducers, initialState);