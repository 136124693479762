import React, { Component } from 'react';
import LoadMask from "Utils/LoadMaskTO";
import Detalle from './Detalle';
import './detalle.css';
import Footer from '../Footer';

export default class DetalleProductos extends Component{

    componentWillMount() {
        const { props, props: { match: { params: { slug_producto } } } } = this;
        props.detalleProducto(slug_producto);
    }

    render() {
        const { loader } = this.props;
        return (
            <div style={{backgroundColor: "#F5F5F5"}}>
                <LoadMask loading={loader} dark blur>
                    <Detalle {...this.props} />
                </LoadMask>
            </div>
        );
    }
}
