import { connect } from 'react-redux'
import Detalle from './DetalleOrdenOnline'
import { actions } from '../../../../redux/modules/orden_online/orden_online'

const ms2p = (state) => {
    const {
        sucursales
    } = state.usuario.me;
    let lista_sucursales = [];
    if (sucursales) {
        sucursales.forEach(sucursal => {
            if (sucursal.id != "empresa") {
                lista_sucursales.push({
                    id: sucursal.id,
                    value: sucursal.id,
                    label: sucursal.nombre
                })
            }
        });
    }

    return {
        ...state.ordenes_online,
        sucursal: state.usuario.sucursal,
        lista_sucursales,
    };

};

const md2p = {
  ...actions
}

export default connect(ms2p, md2p)(Detalle)