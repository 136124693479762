import './sidebar'
import { Range } from 'rc-slider';
import { connect } from 'react-redux'
import React, { Component } from 'react'
import ArbolCategorias from '../ArbolCategorias'
import { slide as Menu } from 'react-burger-menu'
import { actions as actionsHome } from '../../../redux/modules/home'
import { actions } from '../../../redux/modules/productos'

class SideBar extends Component {
    changeCategoria = (slug) => {
        this.props.changeCategoria(slug)
        this.props.setFiltrosAplicados(true)
    }

    render() {
        //  State
        const { state_productos, state_home, vista, isMobile } = this.props;
        const IconoFiltro = (props) => {
            return(
                <div>
                    <img src={require("assets/img/icons/filtros.png")} />
                    { props.filtros_aplicados && (
                        <div className="filtros-aplicados"></div>
                    )}
                </div>
            )
        }
        if (vista == "tienda" && isMobile) {
            //  State
            const { data, precio_inicial_productos, precio_final_productos, categoria_seleccionada, url_productos, categorias, filtros_aplicados } = state_productos;
    
            //  Bind
            const { filtroPrecioInicialChange, filtroPrecioFinalChange, listar, getCategorias, setFiltrosAplicados } = this.props;
    
            const onSliderChange = (value) => {
                filtroPrecioInicialChange(value[0]);
                filtroPrecioFinalChange(value[1])
            }
        
            const filtrarXPrecio = (value) => {
                listar(true);
                getCategorias(categoria_seleccionada)
                setFiltrosAplicados(true);
            }
    
            return (
                <Menu
                    pageWrapId={"contenido"}
                    outerContainerId={"app-container"}
                    customBurgerIcon={<IconoFiltro filtros_aplicados={filtros_aplicados}/> }
                    right>
                        {
                            data && (
                                <div className="w-100 mb-5 filtros">
                                    <div className="txt-filtro mb-3">Filtrar por precio</div>
                                    <div className="d-flex flex-wrap flex-column">
                                        <span className="slider-text w-100">{`Desde Q.${precio_inicial_productos} a Q.${precio_final_productos}`}</span>
                                        <Range
                                            min={data.min_price > 0 ? Math.ceil(data.min_price) - 1 : data.min_price}
                                            max={data.max_price ? Math.ceil(data.max_price) + 1 : 0}
                                            defaultValue={[precio_inicial_productos, precio_final_productos]}
                                            value={[precio_inicial_productos, precio_final_productos]}
                                            tipFormatter={value => `${value}%`}
                                            allowCross={true}
                                            onChange={onSliderChange}
                                            onAfterChange={filtrarXPrecio}
                                        />
                                    </div>
                                </div>
                            )
                        }
                        <ArbolCategorias
                            url={url_productos}
                            categorias={categorias}
                            atras={categoria_seleccionada ? true : false}
                            categoria_seleccionada={categoria_seleccionada}
                            en_producto={true}
                            changeCategoria={this.changeCategoria}/>
                </Menu>
            )
        }else if(vista == "home" && isMobile) {
            //  State
            const { categorias, categoria_seleccionada, categorias_activas } = state_home;
            //  Bind
            const { changeCategoriaHome } = this.props;
            return (
                <Menu
                    burgerButtonClassName="bm-burger-butto-home"
                    pageWrapId={"contenido"}
                    outerContainerId={"app-container"}
                    customBurgerIcon={<IconoFiltro/> }
                    right>
                        <ArbolCategorias
                            movil_empresas={isMobile}
                            categorias={categorias_activas.length ? categorias_activas : categorias}
                            categoria_seleccionada={categoria_seleccionada}
                            atras={categorias_activas.length ? true : false}
                            changeCategoria={changeCategoriaHome} titulo="Categorías de tiendas"/>
                </Menu>
            )
        }
        return (
            <span className="no-content-sidebar"></span>
        )
    }
}

const mstp = state => ({
    state_productos: {
        data: state.productos.data,
        precio_inicial_productos: state.productos.precio_inicial_productos,
        precio_final_productos: state.productos.precio_final_productos,
        categoria_seleccionada: state.productos.categoria_seleccionada,
        url_productos: state.productos.url_productos,
        categorias: state.productos.categorias,
        filtros_aplicados: state.productos.filtros_aplicados
    },
    state_home: {
        categorias: state.home.categorias,
        categoria_seleccionada: state.home.categoria_seleccionada,
        categorias_activas: state.home.categorias_activas

    }
})

const mdtp = {
    filtroPrecioInicialChange: actions.filtroPrecioInicialChange,
    filtroPrecioFinalChange: actions.filtroPrecioFinalChange,
    listar: actions.listar,
    getCategorias: actions.getCategorias,
    changeCategoria: actions.changeCategoria,
    setFiltrosAplicados: actions.setFiltrosAplicados,

    //  Actions Home
    changeCategoriaHome: (id) => dispatch => dispatch(actionsHome.changeCategoria(id))
}

const SideBarComponent = connect(mstp, mdtp)(SideBar)
export default SideBarComponent

