import React from "react";

const ExpandColumn = ({ isExpandableRow, isExpanded }) => {
    let content = "";

    if (isExpandableRow) {
        if (isExpanded) {
            return (
                <div className="text-center">
                    <em className="fa fa-caret-down" />
                </div>
            );
        } else {
            return (
                <div className="text-center">
                    <em className="fa fa-caret-right" />
                </div>
            );
        }
    } else {
        content = " ";
    }
    return <div className="bg-blanco"> {content} </div>;
};

export default ExpandColumn;
